import "./about.css";
import AboutConnectWithUs from "../About/AboutConnectwithUs"

const AboutUs = () => {
  return (
    <div className="about-container">
      <div className="about-section">
        <div className="about-block-one">
          <div className="about-title">Overview</div>
          <div className="about-tagline">GOLDENPROEPRTY CASTLE  a concern of CANH Infrastructure Ltd., is a project catering to the needs of common man. It offers Residential plots at Mysuru – Hunsur NH 275  near Manuganahalli Checkpost. A destination that is   favourite among residents of Musuru and surrounding areas .This is a project to match your lifestyle requirements. We have  been serving clients for a number of years. Property rates are booming and there can be no better time to invest your hard earned money in profitable deals. Our professionals keep a close watch on the property market and the value of residential properties in different cities and towns of the state.  Whenever you buy Land on the outskirts or in a developing colony , its price is expected to appreciate just as soon as construction activity begins. Historically Land prices in  India have risen upwards and given handsome returns to Investors and genuine buyers alike. One may sell at a profit or stay invested and build a house for your family. Our Experienced Planners on board will ensure that this project is future ready with smart design and efficient layout. Keeping your way of life in mind, this is a Project that gets you as close as you can get!</div>
          <div className="about-title">Why Invest with US?</div>
          <div className="about-tagline">At Golden Property, we understand the significance of our customer’s needs and strive to serve it.</div>
          <div className="about-customer-title"> ‘Customer is the king’ is our mantra.</div>

          <div className="about-why-invest-with-us-container ">
            <div className="text-with-icon-contsiner ">
              <div>
                {[
                  {
                    index: "01",
                    title: "Transparency",
                    subTitle:
                      "At Golden Property, we PROMISE that utmost transparency in our processes and offer best practices driven by our ethos.",
                  },
                  {
                    index: "02",
                    title: "Top Notch Services",
                    subTitle:
                      "At Golden Property, we are COMMITTED to provide superlative service to our customers to make it a hassle-free experience.",
                  },
                  {
                    index: "03",
                    title: "Satisfaction",
                    subTitle:
                      "At Golden Property, we offer the best market pricing and believe this is the ideal way of expressing our gratitude.",
                  },
                ].map((d, i) => (
                  <div key={d.index} className="about-why-us-card">
                    <div className="why-us-transparency-number">
                      {d.index}
                    </div>
                    <div className="why-us-transparency-block">
                      <div className="why-us-transparency-title">
                        {d.title}
                      </div>
                      <div className="why-us-transparency-sub-title">
                        {d.subTitle}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="about-title">Features and Amenities</div>
          <div className="about-tagline">Residential plots will be allotted on seniority basis in accordance to Government approvals – DTCP & RERA chosen from our land bank in Boochalli, Bettadooru, Devarahalli, Hullenahalli, Manuganahalli, Hallada Kallhalli, Halebeed kaval, situated in Bilikere Hobli.
        </div>
      </div>

        <div className="about-block-two">
          <AboutConnectWithUs />
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
