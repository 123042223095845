// import React from "react";

import './index.css';

import React, { useState, useMemo } from "react";
import Pagination from './Pagination';
import blog1 from "../../assets/blogsection/blogimage1.png";
import blog2 from "../../assets/blogsection/blogimage2.png";
import blog3 from "../../assets/blogsection/blogimage3.png";
import blog4 from "../../assets/blogsection/blogimage4.png";
import blog5 from "../../assets/blogsection/blogimage5.png";

import blogvector1 from "../../assets/blogsection/blogvector1.png"
import blogvector2 from "../../assets/blogsection/blogvector2.png"
import bloginbox from "../../assets/blogsection/bloginbox.png"
import blogshare from "../../assets/blogsection/blogshare.png";


import BlogConnectwithUs from "./blogConnectwithUs"

let blogsectionData = [

    {
        image: blog1,
        title: "Why Should You Invest in Residential Properties in Bangalore?",
        vectoricon: blogvector1,
        vectoricon1: blogvector2,
        vectoricon2: bloginbox,
        vectoricon3: blogshare,
        vectornumber: "300",
        vectornumber1: "46",
        vectornumber2: "233",
        paragraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.",
        subparagraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.",
      
    },

    {
        image: blog2,
        title: "Why Should You Invest in Residential Properties in Bangalore?",
        vectoricon: blogvector1,
        vectoricon1: blogvector2,
        vectoricon2: bloginbox,
        vectoricon3: blogshare,
        vectornumber: "300",
        vectornumber1: "46",
        vectornumber2: "233",
        paragraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of",
        subparagraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.",
       
    },
    {
        image: blog3,
        title: "Why Should You Invest in Residential Properties in Bangalore?",
        vectoricon: blogvector1,
        vectoricon1: blogvector2,
        vectoricon2: bloginbox,
        vectoricon3: blogshare,
        vectornumber: "300",
        vectornumber1: "46",
        vectornumber2: "233",
        paragraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of",
        subparagraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.",
        
    },
    {
        image: blog4,
        title: "Why Should You Invest in Residential Properties in Bangalore?",
        vectoricon: blogvector1,
        vectoricon1: blogvector2,
        vectoricon2: bloginbox,
        vectoricon3: blogshare,
        vectornumber: "300",
        vectornumber1: "46",
        vectornumber2: "233",
        paragraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of",
        subparagraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.",
      
    },
    {
        image: blog5,
        title: "Why Should You Invest in Residential Properties in Bangalore?",
        vectoricon: blogvector1,
        vectoricon1: blogvector2,
        vectoricon2: bloginbox,
        vectoricon3: blogshare,
        vectornumber: "300",
        vectornumber1: "46",
        vectornumber2: "233",
        paragraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of",
        subparagraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.",
      
    },
    {
        image: blog2,
        title: "Why Should You Invest in Residential Properties in Bangalore?",
        vectoricon: blogvector1,
        vectoricon1: blogvector2,
        vectoricon2: bloginbox,
        vectoricon3: blogshare,
        vectornumber: "300",
        vectornumber1: "46",
        vectornumber2: "233",
        paragraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of",
        subparagraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.",
      
    },
    {
        image: blog2,
        title: "Why Should You Invest in Residential Properties in Bangalore?",
        vectoricon: blogvector1,
        vectoricon1: blogvector2,
        vectoricon2: bloginbox,
        vectoricon3: blogshare,
        vectornumber: "300",
        vectornumber1: "46",
        vectornumber2: "233",
        paragraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of",
        subparagraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.",
       
    },
    {
        image: blog1,
        title: "Why Should You Invest in Residential Properties in Bangalore?",
        vectoricon: blogvector1,
        vectoricon1: blogvector2,
        vectoricon2: bloginbox,
        vectoricon3: blogshare,
        vectornumber: "300",
        vectornumber1: "46",
        vectornumber2: "233",
        paragraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of",
        subparagraph: "Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.Bangalore has always been a potential Residential Property investment destination with the hub of IT companies in the city. Due to its vast culture, the city attracts people from all over the nation. Bangalore alone has a total of 40% of shares in the Indian IT sector which emerges itself into one of the fastest developing metro cities in india. As the population in banglore is rising there has been a shift in the demand for houses has well as plots for investment purpose.",
      
    },


]

let PageSize = 3;



function BlogSection() {

    const [currentPage, setCurrentPage] = useState(1);
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return blogsectionData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);





    return (

        <div className="blog-section-container" >

            {currentTableData.map((d, i) => {
                return <Blog key={i} image={d.image} title={d.title} vectoricon={d.vectoricon} vectoricon1={d.vectoricon1} vectoricon2={d.vectoricon2} vectoricon3={d.vectoricon3} vectornumber={d.vectornumber} vectornumber1={d.vectornumber1} vectornumber2={d.vectornumber2} paragraph={d.paragraph} subparagraph={d.subparagraph} text={d.text} />
            })}

            <div className='pagination-display'>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={blogsectionData.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>

            <div className='blog-pop-up'>
                <BlogConnectwithUs />
            </div>
        </div>


    )

}
const Blog = ({ image, title, vectoricon, vectoricon1, vectoricon2, vectoricon3, paragraph, subparagraph, text, vectornumber, vectornumber1, vectornumber2, }) => {

    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);

    };

    return <div className="blog-container">
        <div className='blog-section-image-container'>
            <div className='blog-image' >

                <img src={image} alt="img" className='blog-image-1' />

            </div>
            <div className="blog-section-tag-line">{title}</div>
            <div className="blog-section-vector-icon">
                <div className="blog-section-vector-icon1">

                    <button onClick={(e) => {
                        alert("");
                    }}><img src={vectoricon} alt="" />
                    </button>



                    <div className="blog-section-vector-icon-number">{vectornumber}</div>
                </div>
                <div className="blog-section-vector-icon1">

                    <button onClick={(e) => {
                        alert("");
                    }}><img src={vectoricon1} alt="" />

                    </button>

                    <div className="blog-section-vector-icon-number">{vectornumber1}</div>
                </div>
                <div className="blog-section-vector-icon1"><img src={vectoricon2} alt="" />
                    <div className="blog-section-vector-icon-number">{vectornumber2}</div>
                </div>
                <div className="blog-section-vector-icon2">
                    <button onClick={(e) => {
                        alert("");
                    }}><img src={vectoricon3} alt="" />

                    </button>

                </div>
            </div>
            <div className="blog-section-paragraph">{paragraph}</div>
            <div className="blog-section-sub-paragraph">

                {isReadMore ? subparagraph.slice(0, 0) : subparagraph}
                <span onClick={toggleReadMore} className="blog-section-read-more-text">
                    {isReadMore ? "Read More" : " Read Less"}
                </span>
            </div>
        </div>
        <div className='blog-section-pop-container'></div>
        {/* <div className="blog-section-image">
            <div className='img' style={{border:"2px solid red"}}>

            <img src={image} alt="" className='blog-image' />

            </div>
            <div className="blog-section-tag-line">{title}</div>
            <div className="blog-section-vector-icon">
                <div className="blog-section-vector-icon1">

                    <button onClick={(e) => {
                        alert("");
                    }}><img src={vectoricon} alt="" />
                    </button>



                    <div className="blog-section-vector-icon-number">{vectornumber}</div>
                </div>
                <div className="blog-section-vector-icon1">

                    <button onClick={(e) => {
                        alert("");
                    }}><img src={vectoricon1} alt="" />

                    </button>

                    <div className="blog-section-vector-icon-number">{vectornumber1}</div>
                </div>
                <div className="blog-section-vector-icon1"><img src={vectoricon2} alt="" />
                    <div className="blog-section-vector-icon-number">{vectornumber2}</div>
                </div>
                <div className="blog-section-vector-icon2">
                    <button onClick={(e) => {
                        alert("");
                    }}><img src={vectoricon3} alt="" />

                    </button>

                </div>
            </div>
            <div className="blog-section-paragraph">{paragraph}</div>
            <div className="blog-section-sub-paragraph">

                {isReadMore ? text.slice(0, 150) : subparagraph}
                <span onClick={toggleReadMore} className="blog-section-read-more-text">
                    {isReadMore ? "Read More" : " Read Less"}
                </span>
            </div>
        </div>
       <div className='blog-section-pop-container'></div> */}

    </div>

}
export default BlogSection;