import "./index.css";
import React, { useState, useRef } from "react";
import HeaderLink from './HeaderLink'
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import goldenPropertyLogo from "../../assets/appLogos/goldenPropertyLogo.svg";
import goldenPropertyLogoText from "../../assets/appLogos/goldenPropertyLogoText.svg";
import dakshaPropertyLogo from "../../assets/appLogos/dakshaPropertyLogo.svg";
import dakshaPropertyLogoText from "../../assets/appLogos/dakshaPropertyLogoText.svg";
import residential from "../../assets/appLogos/residential.png";
import farmland from "../../assets/appLogos/farmland.png";
import logo from "../../assets/appLogos/logo.png"
import closeButton from "./close-button.png";


const Index = ({ updateProjectFilter = (parm) => (parm) }) => {
  const [showHeaderLinkDropdown, setShowHeaderLinkDropdown] = useState(false);
  const ref = useRef(null);
  const ref1 = useRef(null);
  const [click, setClick] = useState(false);
  const [showProjectmobileViewSubmenue, setShowProjectmobileViewSubmenue] = useState(false);
  const [isOpen, setOpen] = useState();
  const [projectLinkOnHover, setprojectLinkOnHover] = useState(false);
  const [isOpenCloud, setOpenCloud] = useState();
  const handleClick = () => setClick(!click);

  return (
    <div className="header-container">

      <div className="header-container-content"
        onMouseLeave={e => setprojectLinkOnHover(false)}>
        <div className="header-primary-container">
          <HeaderLogo />
          <div style={{ flex: 1 }}></div>
          <div className="header-container-list"
            // style={{ display: "flex", alignItems: "center" ,border:"1px solid red" }}
          >
            <a href="/" className="header-container-list-txt" >
              <div className="header-link">HOME</div>
            </a>
            <a href="/about-us" className="header-container-list-txt">
              <div className="header-link">ABOUT</div>
            </a>
             
            {/* <a href="#projects" style={{ textDecoration: "none" }}>
              <div className="header-link">PROJECTS</div>
            </a> */}
            <div
              ref={ref}
              to="projects"
              className="header-link"

              onMouseEnter={() => {
                setOpen(true)
                setprojectLinkOnHover(true)
              }}
            >
              PROJECTS
            </div>
            <a href="/blogs" className="header-container-list-txt">
              <div className="header-link">BLOGS</div>
            </a>
            <a href="#awards" className="header-container-list-txt">
              <div className="header-link">AWARDS</div>
            </a>
            <a href="#contact" className="header-container-list-txt">
              <div className="header-link">CONTACT</div>
            </a>
            <div className="header-ham">
              <svg
                width="30"
                height="20"
                viewBox="0 0 30 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={(e) => setShowHeaderLinkDropdown(!showHeaderLinkDropdown)}
              >
                <path
                  d="M0.740295 18.1275C0.740295 19.0253 1.46807 19.753 2.36583 19.753H28.3744C29.2722 19.753 30 19.0253 30 18.1275C30 17.2297 29.2722 16.502 28.3744 16.502H2.36583C1.46807 16.502 0.740295 17.2297 0.740295 18.1275ZM9 9.99981C9 10.8976 9.72778 11.6253 10.6255 11.6253H28.3744C29.2722 11.6253 30 10.8976 30 9.99981C30 9.10205 29.2722 8.37427 28.3744 8.37427H10.6255C9.72778 8.37427 9 9.10205 9 9.99981ZM2.36583 0.246582C1.46807 0.246582 0.740295 0.97436 0.740295 1.87212C0.740295 2.76988 1.46807 3.49766 2.36583 3.49766H28.3744C29.2722 3.49766 30 2.76988 30 1.87212C30 0.97436 29.2722 0.246582 28.3744 0.246582H2.36583Z"
                  fill="#212325"
                  fillOpacity="0.4"
                />
              </svg>
            </div>
            {/* <div className="header-ham1">
            
               <img src={closeButton} alt="" onClick={(e) => setShowHeaderLinkDropdown(false)}/>
              
            </div> */}
          </div>

        </div>
        <div >
          {projectLinkOnHover && <div className="header-seccondary-container header-link" style={{ height: 56, width: "22rem", position: "relative", left: "50rem" }}>
            <div>

              <span className="dropdown">
                <a className="dropdown1" href={`${process.env.PUBLIC_URL}/#residential-section`}
                  onClick={e => updateProjectFilter("RESIDENTIAL-PLOTS")}
                >Residential Plos</a>
                <img src={residential} alt="" className="dropdown-logo" />


              </span>
              <span className="dropdown2">
                <img src={farmland} alt="" className="dropdown-logo2" />
                <a className="dropdown1"
                  onClick={e => updateProjectFilter("FARMLAND")}
                  href={`${process.env.PUBLIC_URL}/#farm-land-section`}>Farm Land</a>

              </span>
            </div>

          </div>}
        </div>
        {showHeaderLinkDropdown && (
          <div style={{ background: "#FFFF" }} className="nav-menu active">
            <div style={{ padding: 5 }}>
              <a href="/" style={{ textDecoration: "none" }}>
                <div className="header-link-mobile">HOME</div>
              </a>
              <a href="/about-us" style={{ textDecoration: "none" }}>
                <div className="header-link-mobile">ABOUT</div>
              </a>
              {/* <a href="#projects" style={{ textDecoration: "none" }}> */}
              <div className="header-link-mobile" onClick={e => setShowProjectmobileViewSubmenue(!showProjectmobileViewSubmenue)}>PROJECTS</div>
              {showHeaderLinkDropdown && showProjectmobileViewSubmenue &&

                <div className="header-link-mobile">

                  <div style={{ height: "30px", display: "inline-grid", }}
                  >

                    <span className="dropdowns" >
                      <a className="dropdowns1" style={{ textDecoration: "none", color: "#212325" }} href={`${process.env.PUBLIC_URL}/#residential-section`}
                        onClick={e => updateProjectFilter("RESIDENTIAL-PLOTS")}>Residential Plots</a>
                      {/* <img src={residential} alt="" className="dropdown-logos" /> */}


                    </span>
                    <span className="dropdowns2">
                      {/* <img src={farmland} style={{ textDecoration: "none" }} alt="" className="dropdown-logo3" /> */}
                      <a className="dropdowns1" style={{ textDecoration: "none", color: "#212325" }} onClick={e => updateProjectFilter("FARMLAND")}
                        href={`${process.env.PUBLIC_URL}/#farm-land-section`}>Farm Land</a>

                    </span>
                  </div>


                </div>


              }
              {/* </a> */}
              <a href="/blogs" style={{ textDecoration: "none" }}>
                <div className="header-link-mobile">BLOGS</div>
              </a>
              <a href="#awards" style={{ textDecoration: "none" }}>
                <div className="header-link-mobile">AWARDS</div>
              </a>
              <a href="#contact" style={{ textDecoration: "none" }}>
                <div className="header-link-mobile">CONTACT</div>
              </a>
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

const HeaderLogo = () => {
  return (
    <a href="/">
      <div style={{ display: "flex" }}>
        <div style={{ paddingTop: 10 }}>
          <div style={{ display: "flex", justifyContent: "center", width: "198.31px", height: "67.27px" }}>
            <img src={logo} alt="goldenPropertyLogo" />
          </div>
         
          <div></div>
        </div>
     
      </div>
    </a>
  );
};

export default Index;
