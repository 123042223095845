import "./index.css";
import LogoImage from "../FooterSection/logo.png";
import youtube from "../FooterSection/Youtube.png";
import facebook from "../FooterSection/FB.png"
import insta from "../FooterSection/Insta.png";
import twitter from "../FooterSection/Twitter.png";
import vector from "../FooterSection/Vector.png";
import phone from "../FooterSection/phone.png";
import inbox from "../FooterSection/inbox.png";
import location from "../FooterSection/location.png";
import playstore from "../FooterSection/playstore.png";
import apple from "../FooterSection/apple.png"
import { useEffect, useState } from "react";

const FooterSection = () => {
    const [contactUs, setContactUs] = useState();
    useEffect(() => {
        loadReachUs((result) => {
            try {
                setContactUs(JSON.parse(result));
            } catch (e) {
                console.log(e);
            }
        });
    }, []);
    return (
        <div className="footer">
            <div className="footer-container">
                <div className="footer-content-container">
                    <div className="footer-contentSection-1">
                        <div className="footer-logo-container">
                            <img src={LogoImage} alt="logo" />
                        </div>
                        <p>GOLDENPROEPRTY CASTLE a concern of CANH Infrastructure Ltd., is a project catering to the needs of common man. It offers Residential plots at Mysuru – Hunsur NH 275 near Manuganahalli Checkpost.
                            A destination that is favourite among residents of Musuru and surrounding areas .This is a project to match your lifestyle requirments.
                            We have been serving clientsfor a number of years.</p>
                        <div className="footer-socialmedia-link-container-1" >
                            <div>
                                <a
                                    traget="_new"
                                    href="https://twitter.com"
                                >
                                    <img
                                        className="footer-social-link-icons"
                                        src={twitter}
                                        alt="twitter"
                                    />
                                </a>
                                <a
                                    traget="_new"
                                    href="https://www.facebook.com"
                                >
                                    <img
                                        className="footer-social-link-icons"
                                        src={facebook}
                                        alt="facebook"

                                    />
                                </a>
                                <a
                                    traget="-new"
                                    href="https://www.instagram.com"
                                >
                                    <img
                                        className="footer-social-link-icons"
                                        src={insta}
                                        alt="insta"
                                    />
                                </a>
                                <a
                                    traget="_blank"
                                    href="https://youtube.com"
                                >
                                    <img
                                        className="footer-social-link-icons"
                                        src={youtube}
                                        alt="youtube"
                                    />
                                </a>
                            </div>
                        </div>

                    </div>
                    <div className="footer-contentSection-2">
                        <div className="footer-header">Quick Links</div>
                        <div className="footer-header">
                            <img src={vector} alt="" className="vector-image" />
                            <a href="/" className="footer-links">
                                HOME
                            </a>
                        </div>
                        <div className="footer-header">
                            <img src={vector} alt="" />
                            <a href="/about-us" className="footer-links">
                                ABOUT US
                            </a>
                        </div>

                        <div className="footer-header">
                            <img src={vector} alt="" />
                            <a href="/blogs" className="footer-links">
                                BLOGS
                            </a>
                        </div>
                        <div className="footer-header">
                            <img src={vector} alt="" />
                            <a href="/#contact" className="footer-links">
                                CONTACT US
                            </a>
                        </div>
                    </div>
                    <div className="footer-contentSection-3">
                        <div className="footer-header">CONTACT US</div>
                        <div className="footer-contact-us-content-container">
                            <img className="footer-social-icons" src={phone} alt="phone" />
                            <div className="footer-content">
                                {contactUs?.phones || "90354 79766, 90354 79767"}
                            </div>
                        </div>
                        <div className="footer-contact-us-content-container">
                            <img className="footer-social-icons" src={inbox} alt="inbox" />
                            <div className="footer-content">
                                {contactUs?.emails || "golden@property.com"}
                            </div>
                        </div>
                        <div className="footer-contact-us-content-container">
                            <img
                                className="footer-social-icons"
                                src={location}
                                alt="location"
                            />

                            <div className="footer-content">
                                {contactUs?.address ||
                                    " Basaveshwara Nagar, Bangalore-560079"}
                            </div>
                        </div>
                        <div className="footer-golden-property-online">EXPERIENCE Golden Property APP ON MOBILE</div>
                        <div className="footer-online-app-container">
                            <div className="footer-online-app">
                                <div className="footer-online-app-icon">
                                    <img src={playstore} alt="" />
                                    <a href="footer" className="footer-online-app-text">
                                        Coming Soon
                                    </a>
                                </div>
                            </div>
                            <div className="footer-online-app">
                                <div className="footer-online-app-icon">
                                    <img src={apple} alt="" />
                                    <a href="footer" className="footer-online-app-text">
                                        Coming Soon
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="footer-devider">

                </div>
                <div className="footer-socialmedia-link-container-hide">
                    <div>
                        <a
                            traget="_new"
                            href="https://twitter.com"
                        >
                            <img
                                className="footer-social-link-icons"
                                src={twitter}
                                alt="twitter"
                            />
                        </a>
                        <a
                            traget="_new"
                            href="https://www.facebook.com"
                        >
                            <img
                                className="footer-social-link-icons"
                                src={facebook}
                                alt="facebook"

                            />
                        </a>
                        <a
                            traget="-new"
                            href="https://www.instagram.com"
                        >
                            <img
                                className="footer-social-link-icons"
                                src={insta}
                                alt="insta"
                            />
                        </a>
                        <a
                            traget="_blank"
                            href="https://youtube.com"
                        >
                            <img
                                className="footer-social-link-icons"
                                src={youtube}
                                alt="youtube"
                            />
                        </a>
                    </div>
                </div>
                <div className="footer-bottom">
                    Copyright © 2021 Golden Property - All Rights Reserved.
                </div>
            </div>
        </div>
    );
};
export default FooterSection;

function loadReachUs(cb) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };


}
