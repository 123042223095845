import React, { useEffect, useState } from "react";
import "./index.css";
import "react-phone-number-input/style.css";
// import { apiBase } from "../../config/routes";
import PhoneInput from "react-phone-number-input";
import kmp from "./images/contact-gif.gif";
import closeButton from "./images/close-button.png";
import fabCar from "./images/fin-fab.gif";
import desktopCloseButton from "./images/base.png";
import mobileFabCar from "./images/mobile-fab-car.png";
import mobileCloseButton from "./images/mobile-close-fab.png"

function Overlay({ isOpen, onClose,children }) {
  return (
    <>
      {isOpen && (
        <div className="overlay">
        <div className="overlay__background"/>
      </div>
      )}
    </>
  );
}

function ConnectwithUs() {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [contactInfo, setContactInfo] = useState({});
  const [formOpen, setFormOpen] = useState(true);

  const [isOpen, setIsOpen] = useState(true);
  /*   useEffect(() => {
      loadContactInfo((result) => {
        try {
          setContactInfo(JSON.parse(result));
        } catch (e) {
          console.log(e);
        }
      });
    }, []); */
  return (
    <>
    <Overlay isOpen={isOpen}>
</Overlay>
        <div className="float-car-image">
        <img style={{ borderRadius: "24px"}}
          src={fabCar}
          alt="car"
          onClick={() => {
            setFormOpen(true);

          }}
        />
    </div>  
      {/* <div className="close-car-image">
          <img style={{ borderRadius: "24px"}}
            src={desktopCloseButton}
            alt="car"
            onClick={() => {
              setFormOpen(false);
            
            }}
          /> 
          
        </div> */}
     <div className="mobile-fab-car-image">
    <img style={{borderRadius:"24px"}}
            src={mobileFabCar}
            alt="car"
            onClick={() => {
              setFormOpen(true);
              setIsOpen(!isOpen);
            }}
          />
    </div>
     {/*  <div className={closButton ?"mobile-close-image" : "hide"} >
          <img style={{borderRadius:"24px"}}
            src={mobileCloseButton}
            alt="car"
            onClick={() => {
              setFormOpen(false);
              setIsOpen(!isOpen);
              setclosButton(false);
            }}
          />
          
        </div> */}
        
    
    <div className={formOpen ? "connect-info-container" : "hide"} id="connect-info">
      <div className= "connect-info-body">

        <div className="connect-info-form-container">
          <div
            className="close-button"
            onClick={() => {
              setFormOpen(false);
              setIsOpen(!isOpen);
            }}
          >
            <img src={closeButton} />
          </div>
          <div className="connect-info-header">Connect with us</div>
          <div style={{}}>
            <div className="connect-info-form-input-container">
              <div className="connect-info-form-title">Full Name</div>
              <div>
                <input
                  className="connect-info-form-input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="connect-info-form-input-container">
              <div className="connect-info-form-title">Phone Number</div>
              <div>
                <PhoneInput
                  value={phone}
                  className="connect-info-form-input"
                  defaultCountry="IN"
                  onChange={setPhone}
                />
              </div>
            </div>

            <div className="connect-info-form-input-container">
              <div className="connect-info-form-title">Email</div>
              <div>
                <input
                  className="connect-info-form-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="connect-info-form-input-container">
              <div className="connect-info-form-title">Message</div>
              <div>
                <textarea
                  style={{ height: 100 }}
                  placeholder="message"
                  className="connect-info-form-input"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div> */}
          </div>
          <div style={{ display: "flex" }}>
            <button
              className="connect-info-form-button"
              onClick={(e) => {
                /*   sendMessage(phone, email, message, name, () => {
                    console.log("sent");
                  }); */
                alert("message Sent");
              }}
            >
              CONNECT
            </button>
          </div>

        </div>
        <div className="car-image" >
          <img src={kmp} alt="car" style={{ width: "100%" }} />
        </div>
      </div>
  
    {/* <div className="close-car-image">
          <img style={{borderRadius:"24px",float: "right"}}
            src={desktopCloseButton}
            alt="car"
            onClick={() => {
              setFormOpen(false);
            }}
          />
          
        </div> */}
        {/* <div className="mobile-close-image">
          <img style={{borderRadius:"24px",float: "right"}}
            src={mobileCloseButton}
            alt="car"
            onClick={() => {
              setFormOpen(false);
              setIsOpen(!isOpen);
            }}
          />
          
        </div> */}
        {/* <div className="close-car-image">
          <img
            src={desktopCloseButton}
            alt="car"
            onClick={() => {
              setFormOpen(false);
            }}
          />
          
        </div> */}
        {/* <div className="mobile-close-image">
          <img
            src={mobileCloseButton}
            alt="car"
            onClick={() => {
              setFormOpen(false);
              setIsOpen(!isOpen);
            }}
          />
          
        </div> */}
           <div className="close-car-image">
          <img
            src={desktopCloseButton}
            alt="car"
            onClick={() => {
              setFormOpen(false);
            }}
          />
          
        </div>
        <div className="mobile-close-image" >
          <img style={{borderRadius:"24px"}}
            src={mobileCloseButton}
            alt="car"
            onClick={() => {
              setFormOpen(false);
              setIsOpen(!isOpen);
             
            }}
          />
          
        </div>
    </div>
</>
  );
}

export default ConnectwithUs;

/* function loadContactInfo(cb) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(apiBase + "/ContactInformation/1", requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
} */

/* function sendMessage(phone, email, message, fullName, cb) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AdGXoFKFuGyZcUZiRaZPpN1LFzAh_Vm-5.CS7p24277%2FQk9IxGJPvnuIkS4OMy%2FBIoD0mfQKSl2y8"
  );

  var raw = JSON.stringify({
    phone: phone,
    email: email,
    message: message,
    fullName: fullName,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(apiBase + "/SendUsMessage", requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
} */
