import React, { useEffect, useState } from "react";
import "./index.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import kmp from "./images/contact-gif.gif";
import closeButton from "./images/close-button.png";
import fabCar from "./images/fin-fab.gif";
import desktopCloseButton from "./images/base.png";
import mobileFabCar from "./images/mobile-fab-car.png";
import mobileCloseButton from "./images/mobile-close-fab.png"
function Overlay({ isOpen, onClose, children }) {
  return (
    <>
      {isOpen && (
        <div className="overlay">
          <div className="overlay__background" />
        </div>
      )}
    </>
  );
}
function BlogConnectWithUs() {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [contactInfo, setContactInfo] = useState({});
  const [formOpen, setFormOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      <Overlay isOpen={isOpen}>
      </Overlay>
      <div className="blog-float-car-image">
        <img style={{ borderRadius: "24px" }}
          src={fabCar}
          alt="car"
          onClick={() => {
            setFormOpen(true);

          }}
        />
      </div>
      <div className="blog-mobile-fab-car-image">
        <img style={{ borderRadius: "24px" }}
          src={mobileFabCar}
          alt="car"
          onClick={() => {
            setFormOpen(true);
            setIsOpen(!isOpen);
          }}
        />
      </div>
      <div className={formOpen ? "blog-connect-info-container" : "hide"}>
        <div className="blog-connect-info-body">

          <div className="blog-connect-info-form-container">
            <div
              className="blog-close-button"
              onClick={() => {
                setFormOpen(false);
                setIsOpen(!isOpen);
              }}
            >
              <img src={closeButton} />
            </div>
            <div className="blog-connect-info-header">Connect with us</div>
            <div>
              <div className="blog-connect-info-form-input-container">
                <div className="blog-connect-info-form-title">Full Name</div>
                <div>
                  <input
                    className="blog-connect-info-form-input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="blog-connect-info-form-input-container">
                <div className="blog-connect-info-form-title">Phone Number</div>
                <div>
                  <PhoneInput
                    value={phone}
                    className="blog-connect-info-form-input"
                    defaultCountry="IN"
                    onChange={setPhone}
                  />
                </div>
              </div>
              <div className="blog-connect-info-form-input-container">
                <div className="blog-connect-info-form-title">Email</div>
                <div>
                  <input
                    className="blog-connect-info-form-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="blog-connect-button-container">
              <button
                className="blog-connect-info-form-button"
                onClick={(e) => {
                  alert("message Sent");
                }}
              >
                CONNECT
              </button>
            </div>
          </div>
          <div className="blog-car-image" >
            <img src={kmp} alt="car" style={{ width: "100%" }} />
          </div>
        </div>
        <div className="blog-close-car-image">
          <img
            src={desktopCloseButton}
            alt="car"
            onClick={() => {
              setFormOpen(false);
            }}
          />
        </div>
        <div className="blog-mobile-close-image">
          <img style={{ borderRadius: "24px" }}
            src={mobileCloseButton}
            alt="car"
            onClick={() => {
              setFormOpen(false);
              setIsOpen(!isOpen);
            }}
          />
        </div>
      </div>
    </>
  );
}
export default BlogConnectWithUs;