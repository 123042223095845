import "./award.css";

const AwardImage = ({ image, icon, title, subtitle }) => {
  return (
      <div className="award-image-center-section">
        <div className="award-receice-icon">
          <img src={image} alt="" />
        </div>
        <div className="award-receice-logo">
          <img src={icon} alt="" width={155} height={94} />
        </div>
        <div className="award-receive-title">{title}</div>
        <div className="award-receive-subtitle">{subtitle}</div>
      </div>
  );
};

export default AwardImage;
