import "./index.css";
import bgImage from "../../assets/images/homeBG.png";
import PageSection from "../global/PageSection";
import { useEffect, useRef, useState } from "react";
import { APIBASE } from "../../apiCondig";
import Slider from "react-slick";

const TreandingProjects = () => {
  const [projects, setProjects] = useState([]);
  const sliderRef = useRef();
  useEffect(() => {
    loadprojects((resut) => {
      try {
        setProjects(JSON.parse(resut));
      } catch (e) {
        console.log(e);
      }
    });
  }, []);
  let settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 1392,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 745,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="treanding-projects">
      <PageSection
        title={"Trending Projects"}
        subTitle={"The  Noteworthy Real Estate in India"}
        // bgImage={bgImage}
        style={{
          flex: 1,
          // backgroundSize: "cover",
          margin: 0,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right bottom",
          backgroundColor: "#FBFFF5",
          paddingBottom: 30,
        }}
      >
        <div className="treanding-project-filter-container"
        ></div>
        <div className="trending-project-card-main-container">
          <div className="trending-project-card-container">
            <div style={{}}>
              <Slider {...settings} ref={sliderRef}>
                {projects.map((d, i) => {
                  return (
                    <div key={"k" + i}>
                      <div className="trending-project-cards">
                        <div className="trending-project-cards-contents">
                          <div className="trending-project-image-section">
                            <a
                              style={{ textDecoration: "none" }}
                              href={"/project/" + d.id}
                            >
                              <img
                                src={d.image && d.image[0]}
                                alt={d.title} className="trending-project-image"/>
                            </a>
                          </div>
                          <div className="trending-project-content">
                            <div className="trending-project-title-content">
                              <a
                                style={{ textDecoration: "none" }}
                                href={"/project/" + d.id}
                              >
                                <div className="trending-project-card-title">
                                  {d.title}
                                </div>
                                <div className="trending-project-card-sub-title"
                                >
                                  {d.description}
                                </div>
                              </a>
                            </div>
                            <div className="trending-project-card-btn">
                              <a
                                style={{ textDecoration: "none" }}
                                href={"/project/" + d.id}
                              >
                              <svg
                                width="30"
                                height="16"
                                viewBox="0 0 30 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.253 0.960441C1.12961 1.08357 1.03173 1.22983 0.964937 1.39084C0.898148 1.55185 0.86377 1.72445 0.86377 1.89876C0.86377 2.07307 0.898148 2.24567 0.964937 2.40668C1.03173 2.56769 1.12961 2.71395 1.253 2.83708L6.41708 8.00116L1.253 13.1653C1.00414 13.4141 0.864333 13.7516 0.864333 14.1036C0.864333 14.4555 1.00414 14.793 1.253 15.0419C1.50186 15.2907 1.83938 15.4306 2.19132 15.4306C2.54326 15.4306 2.88078 15.2907 3.12964 15.0419L9.2387 8.93283C9.36208 8.8097 9.45997 8.66344 9.52676 8.50243C9.59355 8.34142 9.62792 8.16882 9.62792 7.99451C9.62792 7.8202 9.59355 7.6476 9.52676 7.48659C9.45997 7.32558 9.36208 7.17932 9.2387 7.05619L3.12964 0.947131C2.62388 0.44137 1.77207 0.44137 1.253 0.960441Z"
                                  fill="#209541"
                                />
                                <path
                                  opacity="0.5"
                                  d="M11.0071 0.960441C10.8838 1.08357 10.7859 1.22983 10.7191 1.39084C10.6523 1.55185 10.6179 1.72445 10.6179 1.89876C10.6179 2.07307 10.6523 2.24567 10.7191 2.40668C10.7859 2.56769 10.8838 2.71395 11.0071 2.83708L16.1712 8.00116L11.0071 13.1653C10.7583 13.4141 10.6185 13.7516 10.6185 14.1036C10.6185 14.4555 10.7583 14.793 11.0071 15.0419C11.256 15.2907 11.5935 15.4306 11.9455 15.4306C12.2974 15.4306 12.6349 15.2907 12.8838 15.0419L18.9928 8.93283C19.1162 8.8097 19.2141 8.66344 19.2809 8.50243C19.3477 8.34142 19.3821 8.16882 19.3821 7.99451C19.3821 7.8202 19.3477 7.6476 19.2809 7.48659C19.2141 7.32558 19.1162 7.17932 18.9928 7.05619L12.8838 0.947131C12.378 0.44137 11.5262 0.44137 11.0071 0.960441Z"
                                  fill="#209541"
                                />
                                <path
                                  opacity="0.2"
                                  d="M20.7613 0.960441C20.6379 1.08357 20.54 1.22983 20.4732 1.39084C20.4064 1.55185 20.3721 1.72445 20.3721 1.89876C20.3721 2.07307 20.4064 2.24567 20.4732 2.40668C20.54 2.56769 20.6379 2.71395 20.7613 2.83708L25.9254 8.00116L20.7613 13.1653C20.5124 13.4141 20.3726 13.7516 20.3726 14.1036C20.3726 14.4555 20.5124 14.793 20.7613 15.0419C21.0102 15.2907 21.3477 15.4306 21.6996 15.4306C22.0516 15.4306 22.3891 15.2907 22.6379 15.0419L28.747 8.93283C28.8704 8.8097 28.9683 8.66344 29.0351 8.50243C29.1018 8.34142 29.1362 8.16882 29.1362 7.99451C29.1362 7.8202 29.1018 7.6476 29.0351 7.48659C28.9683 7.32558 28.8704 7.17932 28.747 7.05619L22.6379 0.947131C22.1322 0.44137 21.2804 0.44137 20.7613 0.960441Z"
                                  fill="#209541"
                                />
                              </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </PageSection>
    </div>
  );
};
export default TreandingProjects;

function loadprojects(cb) {
  var myHeaders = new Headers();
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AVMjWRXtLoViz1nDUyIXJhuFsyJAlPltr.pXO57A0bsp%2FQMBh6x22Ze5rrVm2tGzixJwqUy1q2nAc"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(APIBASE + "/Properties", requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
}
