import "./index.css";
import { useEffect, useRef, useState } from "react";
import PageSection from "../global/PageSection";
import Slider from "react-slick";
import { APIBASE } from "../../apiCondig";

import arrow from "./assets/arrow.svg";
import g1 from "./assets/images/g3.png";
import bgImage from "../../assets/images/bgLeafeBG.png";
const InsightsUtilities = ({}) => {
  const sliderRef = useRef();
  const [amenities, setAmenities] = useState([]);
  useEffect(() => {
    loadAmenities((resut) => {
      try {
        setAmenities(JSON.parse(resut));
      } catch (e) {
        console.log(e);
      }
    });
  }, []);
  var settings = {
    // dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 1392,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 745,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  useEffect(() => {}, []);
  return (
    <PageSection
      title={"More Insights & Utilities"}
      subTitle={"Handpicked projects for you"}
      bgImage={bgImage} 
      style={{
        minHeight: 638,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="more-insights-card-section">
        <div className="more-insights-slider-action-holder">
          <div
            className="more-insights-carousal-left-chevron-btn"
            onClick={() => {
              console.log(sliderRef);
              sliderRef.current.slickPrev();
            }}
          >
            <svg
              width="10"
              height="19"
              viewBox="0 0 10 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.9375 1.47925L0.916667 9.50008L8.9375 17.5209"
                stroke="#5D5F5F"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        <div className="more-insights-cards">
          <div style={{}}>
            <Slider {...settings} ref={sliderRef}>
              {amenities.map((d, i) => {
                return (
                  <div key={"k" + i}>
                    <div
                      className="more-insights-card-container">
                      
                      <div className="more-insights-cards-block-section">
                       
                        <div className="more-insights-cards-title">
                         
                          {d.title}
                        </div>
                        <div className="more-insights-cards-description">
                          
                          {d.description || "  "}
                        </div>
                        <div className="more-insights-cards-image">
                          
                          <img className="more-insights-cards-arrow-mark"
                            src={arrow}
                            alt={"arrow"}
                           
                          />
                          <img
                            src={d.image}
                            alt={d.title}
                            height={51}
                            width={51}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>

         <div className="more-insights-slider-action-holder">
          <div
            className="more-insights-carousal-right-chevron-btn"
            onClick={() => {
              console.log(sliderRef);
              sliderRef.current.slickNext();
            }}
          >
            <svg
              width="10"
              height="19"
              viewBox="0 0 10 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.0625 17.5208L9.08333 9.49992L1.0625 1.47908"
                stroke="#5D5F5F"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div style={{}} className="more-insights-slider-small-action-holder">
        <div
          className="more-insights-carousal-left-chevron-btn"
          style={{ marginTop: 0, left: 0 }}
          onClick={() => {
            console.log(sliderRef);
            sliderRef.current.slickPrev();
          }}
        >
          <svg
            width="10"
            height="19"
            viewBox="0 0 10 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.9375 1.47925L0.916667 9.50008L8.9375 17.5209"
              stroke="#5D5F5F"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div style={{ width: 18 }}></div>
        <div
          className="more-carousal-right-chevron-btn"
          onClick={() => {
            console.log(sliderRef);
            sliderRef.current.slickNext();
          }}
        >
          <svg
            width="10"
            height="19"
            viewBox="0 0 10 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.0625 17.5208L9.08333 9.49992L1.0625 1.47908"
              stroke="#5D5F5F"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </PageSection>
  );
};

function loadAmenities(cb) {
  var myHeaders = new Headers();
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AVMjWRXtLoViz1nDUyIXJhuFsyJAlPltr.pXO57A0bsp%2FQMBh6x22Ze5rrVm2tGzixJwqUy1q2nAc"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(APIBASE + "/Utilities", requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
}
export default InsightsUtilities;
