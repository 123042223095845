import React, { useEffect, useState } from "react";
import "./index.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import kmp from "./images/contact-gif.gif";
import closeButton from "./images/close-button.png";
import fabCar from "./images/fin-fab.gif";
import desktopCloseButton from "./images/base.png";
import mobileFabCar from "./images/mobile-fab-car.png";
import mobileCloseButton from "./images/mobile-close-fab.png"
function Overlay({ isOpen, onClose,children }) {
  return (
    <>
      {isOpen && (
        <div className="overlay">
        <div className="overlay__background"/>
      </div>
      )}
    </>
  );
}
function AboutConnectWithUs() {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [contactInfo, setContactInfo] = useState({});
  const [formOpen, setFormOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
    <Overlay isOpen={isOpen}>
</Overlay>
        <div className="about-us-float-car-image">
        <img style={{ borderRadius: "24px"}}
          src={fabCar}
          alt="car"
          onClick={() => {
            setFormOpen(true);

          }}
        />
    </div>  
     <div className="about-us-mobile-fab-car-image">
    <img style={{borderRadius:"24px"}}
            src={mobileFabCar}
            alt="car"
            onClick={() => {
              setFormOpen(true);
              setIsOpen(!isOpen);
            }}
          />
    </div>        
    <div className={formOpen ? "about-connect-info-container" : "about-hide"}>
      <div className= "about-us-connect-info-body">

        <div className="about-us-connect-info-form-container">
          <div
            className="about-us-close-button"
            onClick={() => {
              setFormOpen(false);
              setIsOpen(!isOpen);
            }}
          >
            <img src={closeButton} />
          </div>
          <div className="about-us-connect-info-header">Connect with us</div>
          <div>
            <div className="about-us-connect-info-form-input-container">
              <div className="about-us-connect-info-form-title">Full Name</div>
              <div>
                <input
                  className="about-us-connect-info-form-input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="about-us-connect-info-form-input-container">
              <div className="about-us-connect-info-form-title">Phone Number</div>
              <div>
                <PhoneInput
                  value={phone}
                  className="connect-info-form-input"
                  defaultCountry="IN"
                  onChange={setPhone}
                />
              </div>
            </div>
            <div className="about-us-connect-info-form-input-container">
              <div className="about-us-connect-info-form-title">Email</div>
              <div>
                <input
                  className="connect-info-form-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="about-us-connect-button-container">
            <button
              className="about-us-connect-info-form-button"
              onClick={(e) => {
                alert("message Sent");
              }}
            >
              CONNECT
            </button>
          </div>
        </div>
        <div className="car-image" >
          <img src={kmp} alt="car" style={{ width: "100%" }} />
        </div>
      </div>
           <div className="about-us-close-car-image">
          <img
            src={desktopCloseButton}
            alt="car"
            onClick={() => {
              setFormOpen(false);
            }}
          />
        </div>
        <div className="about-us-mobile-close-image">
          <img style={{borderRadius:"24px", position:"relative", bottom:"165px", left:"-8px"}}
            src={mobileCloseButton}
            alt="car"
            onClick={() => {
              setFormOpen(false);
              setIsOpen(!isOpen);
            }}
          />
        </div>
    </div>
</>
  );
}
export default AboutConnectWithUs;