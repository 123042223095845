import "./index.css";
import { useEffect, useRef, useState } from "react";
import solarHomeBG2 from "../../assets/images/banner2.png";
import solarHomeBG1 from "../../assets/images/banner1.png";
import PageSection from "../global/PageSection";
import Slider from "react-slick";
const HomeSlider = ({}) => {
  const sliderRef = useRef();

  var settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 1392,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 745,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  useEffect(() => {}, []);
  return (
  /*   <PageSection
      style={{
        // marginTop: 190,
        minHeight: 638,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    > */
      <Slider {...settings}>
        <div>
          <img src={solarHomeBG1} alt="Home Slider" style={{backgroundSize:"cover",width:"100%",cursor:"pointer"}}/>
        </div>
        <div>
          <img src={solarHomeBG2} alt="Home Slider" style={{backgroundSize:"cover",width:"100%",cursor:"pointer"}}/>
        </div>
        </Slider>
    // </PageSection>
  );
};


export default HomeSlider;
