import "./index.css";
import bgImage from "../../assets/images/Image.png";
import PageSection from "../global/PageSection";
import img from "../../assets/images/bottomimg.png";
const WhyUs = () => {
  return (
    <div className="why-us-container">
      <div className="why-us-title">Why Us?</div>
      <div className="why-us-subtitle">At Golden Property, we understand the significance of our customer’s needs and strive to serve it.</div>
      <div className="why-us-content-and-image-container">

        <div className="why-us-content-section">
          <div>
            {[
              {
                index: "01",
                title: "Transparency",
                subTitle:
                  "At Golden Property, we PROMISE that utmost transparency in our processes and offer best practices driven by our ethos.",
              },
              {
                index: "02",
                title: "Top Notch Services",
                subTitle:
                  "At Golden Property, we are COMMITTED to provide superlative service to our customers to make it a hassle-free experience.",
              },
              {
                index: "03",
                title: "Satisfaction",
                subTitle:
                  "At Golden Property, we offer the best market pricing and believe this is the ideal way of expressing our gratitude.",
              },
            ].map((d, i) => (
              <div key={d.index} className="why-us-card1">
                <div className="why-us-transparency-number">
                  {d.index}
                </div>
                <div className="why-us-transparency-block">
                  <div className="why-us-transparency-title">
                    {d.title}
                  </div>
                  <div className="why-us-transparency-sub-title">
                    {d.subTitle}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="why-us-mobile-view-bottom-image-block">
        <img src={img} alt="" className="why-us-mobile-view-bottom-image" />
      </div>
    </div>
  );
};
export default WhyUs;
