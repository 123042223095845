import "./award.css";
import { useRef } from "react";
import Slider from "react-slick";
import { useState, useEffect } from "react";
import AwardImage from "./AwardImage";

let ourprojectimageData = [
  {
    image: process.env.PUBLIC_URL + "/assets/Awards/award.png",
    icon: process.env.PUBLIC_URL + "/assets/Awards/SriSai.png",
    title: "Farm Land Daksha Chandana",
    subtitle:
      "Those days are now passé when purchasing a home used to be a very arduous task ...",
  },
  {
    image: process.env.PUBLIC_URL + "/assets/Awards/award.png",
    icon: process.env.PUBLIC_URL + "/assets/Awards/SriSai.png",
    title: "Farm Land Daksha Chandana",
    subtitle:
      "Those days are now passé when purchasing a home used to be a very arduous task ...",
  },
  {
    image: process.env.PUBLIC_URL + "/assets/Awards/award.png",
    icon: process.env.PUBLIC_URL + "/assets/Awards/SriSai.png",
    title: "Farm Land Daksha Chandana",
    subtitle:
      "Those days are now passé when purchasing a home used to be a very arduous task ...",
  },
];
var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
};
const AwardsReceive = () => {
  const sliderRef = useRef();
  return (
    <div className="award" id="awards">
      <div className="award-container">
        <div className="award-header">Awards Recieved</div>
        <div className="award-sub-header">
          We have been recieved many awards
        </div>
        <div className="award-logo-container">
          <Slider ref={sliderRef} {...settings}>
            {ourprojectimageData.map((d, i) => {
              return (
                <AwardImage
                  key={i}
                  image={d.image}
                  icon={d.icon}
                  title={d.title}
                  subtitle={d.subtitle}
                />
              );
            })}
          </Slider>
          <div className="award-carousal-btn">
            <div className="award-slider-action-holder">
              <div
                className="award-carousal-left-chevron-btn"
                onClick={() => {
                  console.log(sliderRef);
                  sliderRef.current.slickPrev();
                }}
              >
                <svg
                  width="10"
                  height="19"
                  viewBox="0 0 10 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.9375 1.47925L0.916667 9.50008L8.9375 17.5209"
                    stroke="#5D5F5F"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div
              className="award-carousal-right-chevron-btn"
              onClick={() => {
                console.log(sliderRef);
                sliderRef.current.slickNext();
              }}
            >
              <svg
                width="10"
                height="19"
                viewBox="0 0 10 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.0625 17.5208L9.08333 9.49992L1.0625 1.47908"
                  stroke="#5D5F5F"
                  strokeWidth="1.8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AwardsReceive;
