import "./index.css";
import bgImage from "../../assets/images/palace1.png";
import personBG from "../../assets/images/personBG.png";
import PageSection from "../global/PageSection";
import bin from "./images/bin.svg";
import education from "./images/education.svg";
import hospital from "./images/hospital.svg";
import map from "./images/map.svg";
import train from "./images/train.svg";
import sunnyCloude from "./images/sunnyCloude.svg";
import waterPipe from "./images/waterPipe.svg";
import safe from "./images/safe.svg";
import airport from "./images/airport.png";
import flexible from "./images/flexible.png";

const WhyInvestInMysuru = () => {
  return (
    <div className="Why-invest-in-mysuru">
      <div className="why-invest-in-mysure-title">Why Invest in Mysuru</div>
      <div className="why-invest-in-mysure-subtitle">The  Noteworthy Real Estate in India</div>
      <div className="why-invest-cards-container">
        <div className="why-invest-in-mysure-person-block">
          <img
            src={personBG}
            style={{}}
            alt={"bg"}
            className="why-invest-in-mysure-person-image"
          />
        </div>
        <div className="why-invest-points-container-large">
<div style={{display:"block", width:"fit-content"}}> 

          <div className="water-connectivity-and-cleanest-city-container">

            <div className="water">
              <div className="why-invest-icons">
                <div>
                  <img src={waterPipe} alt={"waterPipe"} />
                </div>
                <div className="why-invest-in-mysore-card-title">
                  Water Connectivity
                </div>
              </div>

              <div className="why-invest-in-mysore-card-sub-title">
                According to NITI Aayog, mysuru has 86% water connectivity for <br></br>
                house hold usage, a landmark in itself.
              </div>
            </div>
            <div className="cleanest">
              <div className="why-invest-icons">
                <div>
                  <img src={bin} alt={"waterPipe"} />
                </div>
                <div className="why-invest-in-mysore-card-title">
                  Cleanest City
                </div>
              </div>
              <div className="why-invest-in-mysore-card-sub-title">
                One of the cleanest cities in india. Mysuru was declared as <br></br>
                the cleanest city for several years.
              </div>
            </div>
          </div>


          <div className="water-connectivity-and-cleanest-city-container">
            
            <div className="water">
              <div className="why-invest-icons">
                <div>
                  <img src={education} alt={"waterPipe"} />
                </div>
                <div className="why-invest-in-mysore-card-title">
                Education Hub
                </div>
              </div>
              <div className="why-invest-in-mysore-card-sub-title">
              According to NITI Aayog, mysuru has 86% water connectivity for <br></br>
                house hold usage, a landmark in itself.
              </div>
            </div>
            <div className="cleanest">
              <div className="why-invest-icons">
                <div>
                  <img src={map} alt={"waterPipe"} />
                </div>
                <div className="why-invest-in-mysore-card-title">
                City Connectivity
                </div>
              </div>
              <div className="why-invest-in-mysore-card-sub-title">
                One of the cleanest cities in india. Mysuru was declared as <br></br>
                the cleanest city for several years.
              </div>
            </div>
          </div>

          <div className="water-connectivity-and-cleanest-city-container" >
            <div className="water">
              <div className="why-invest-icons">
                <div>
                  <img src={sunnyCloude} />
                </div>
                <div className="why-invest-in-mysore-card-title">
                  Pleasant Weather
                </div>
              </div>
              <div className="why-invest-in-mysore-card-sub-title">
              According to NITI Aayog, mysuru has 86% water connectivity for <br></br>
                house hold usage, a landmark in itself.
              </div>
            </div>
            <div className="cleanest">
              <div className="why-invest-icons">
                <div>
                  <img src={hospital} />
                </div>
                <div className="why-invest-in-mysore-card-title">
                  Hospitals
                </div>
              </div>
              <div className="why-invest-in-mysore-card-sub-title">
              One of the cleanest cities in india. Mysuru was declared as <br></br>
                the cleanest city for several years.              </div>
            </div>
          </div>
          <div className="water-connectivity-and-cleanest-city-container">
            <div className="water">
              <div className="why-invest-icons">
                <div>
                  <img src={safe} />
                </div>
                <div className="why-invest-in-mysore-card-title">
                  Safe City
                </div>
              </div>
              <div className="why-invest-in-mysore-card-sub-title">
              According to NITI Aayog, mysuru has 86% water connectivity for <br></br>
                house hold usage, a landmark in itself.              </div>
            </div>
            <div className="cleanest">
              <div className="why-invest-icons">
                <div>
                  <img src={train} />
                </div>
                <div className="why-invest-in-mysore-card-title">
                  Double Track Train
                </div>
              </div>
              <div className="why-invest-in-mysore-card-sub-title">
                One of the cleanest cities in india. Mysuru was declared as <br></br>
                the cleanest city for several years.
              </div>
            </div>
          </div>

          <div className="water-connectivity-and-cleanest-city-container-end">
            <div className="water">
              <div className="why-invest-icons">
                <div>
                  <img src={airport} className="end-icons "/>
                </div>
                <div className="why-invest-in-mysore-card-title">
                  Airport
                </div>
              </div>
              <div className="why-invest-in-mysore-card-sub-title">
              According to NITI Aayog, mysuru has 86% water connectivity for <br></br>
                house hold usage, a landmark in itself.              </div>
            </div>
            <div className="cleanest">
              <div className="why-invest-icons">
                <div>
                  <img src={flexible} className="end-icons" />
                </div>
                <div className="why-invest-in-mysore-card-title">
                  Flexible Road
                </div>
              </div>
              <div className="why-invest-in-mysore-card-sub-title">
                One of the cleanest cities in india. Mysuru was declared as <br></br>
                the cleanest city for several years.
              </div>
            </div>
          </div>

          </div>
          <div className="why-invest-in-mysure-person-end-block">
          <img
            src={personBG}
            style={{}}
            alt={"bg"}
            className="why-invest-in-mysure-person-mobile-image"
          />
        </div>
        </div>
      </div>
    </div>
  );
};
export default WhyInvestInMysuru;
