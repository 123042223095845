import "./index.css";
import { useEffect, useRef, useState } from "react";
import PageSection from "../global/PageSection";
import Slider from "react-slick";
import { APIBASE } from "../../apiCondig";

import goldenPropertyLogo from "../../assets/appLogos/goldenPropertyLogo.svg";
import goldenPropertyLogoText from "../../assets/appLogos/goldenPropertyLogoText.svg";
import dakshaPropertyLogo from "../../assets/appLogos/dakshaPropertyLogo.svg";
import dakshaPropertyLogoText from "../../assets/appLogos/dakshaPropertyLogoText.svg";
import bgImage from "../../assets/images/greenfieldBG.png";
const Projects = ({ getCallingFunction }) => {
  const sliderRef = useRef();
  const [selectedFilter, setSelectedFilter] = useState("residential");
  const [showFilterDropDown, setShowFilterDropDown] = useState(false);
  const [projects, setProjects] = useState([]);

  const filter = (fi) => {
    loadprojects(fi, (resut) => {
      try {
        setProjects(JSON.parse(resut));
        if (fi === "FARMLAND") setSelectedFilter("faramland");
        if (fi === "RESIDENTIAL-PLOTS") setSelectedFilter("residential");
      } catch (e) {
        console.log(e);
      }
    });
  };

  useEffect(() => {
    getCallingFunction(filter);
    var url = new URL(document.URL);
    console.log(url.hash, url.hash === "#residential-section");
    if (url.hash === "#farm-land-section") {
      setSelectedFilter("faramland");
      filter("FARMLAND");
    } else {
      setSelectedFilter("residential");
      filter("RESIDENTIAL-PLOTS");
    }
  }, []);
  let settings = {
    // dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 1392,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          //dots: true,
        },
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 745,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div id="project-card">
      <PageSection
        title={"Our Projects"}
        subTitle={"The  Noteworthy Real Estate in India"}
        style={{
          minHeight: 838,
          background: "#FBFFF5",
        }}
      >
        <div
          className="projects-filter-container"
        >
          <div
            style={{
              padding: 14,
              border:
                selectedFilter === "residential"
                  ? "1px solid #209541"
                  : "1px solid #ccc",
              background:
                selectedFilter === "residential" ? "#25A04730" : "#EEEEEE",
              margin: 15,
              borderRadius: 4,
              display: "flex",
              width: 287,
            }}
            onClick={(e) => {
              setSelectedFilter("residential");
              filter("RESIDENTIAL-PLOTS");
            }}
          >
            <div className="project-card-residential-img-block">
              <div className="project-card-residential-img">
                <img
                  src={goldenPropertyLogo}
                  alt="goldenPropertyLogo"
                  width={37.66}
                  height={21.13}
                />
              </div>
              <div style={{}}>
                <img
                  src={goldenPropertyLogoText}
                  alt="goldenPropertyLogo"
                  width={62}
                  height={8.73}
                />
              </div>
              <div></div>
            </div>
            <div className="project-card-residential-text">
              <span
                style={{color: selectedFilter === "residential" ? "#209541" : "#090909",
                }}
              >
                RESIDENTIAL PLOTS
              </span>
            </div>
          </div>
          <div
            className="project-filter-farmland"
            onClick={(e) => {
              setSelectedFilter("faramland");
              filter("FARMLAND");
            }}
            style={{
              padding: 10,
              border:
                selectedFilter === "faramland"
                  ? "1px solid #209541"
                  : "1px solid #ccc",
              background:
                selectedFilter === "faramland" ? "#25A04730" : "#EEEEEE",
              borderRadius: 4,
              margin: 15,
              display: "flex",
              width: 271,
            }}
          >
            <div className="project-card-farmland-img-block">
              <div className="project-card-farmland-img">
                <img
                  src={dakshaPropertyLogo}
                  alt="goldenPropertyLogo"
                  width={18.67}
                  height={20.57}
                />
              </div>
              <div style={{}}>
                <img
                  src={dakshaPropertyLogoText}
                  alt="goldenPropertyLogo"
                  width={48}
                  height={10.57}
                />
              </div>
            </div>

            <div className="project-card-farmland-text">
              <span
                style={{ color: selectedFilter === "faramland" ? "#209541" : "#090909",
                }}
              >
                FARM LAND
              </span>
            </div>
          </div>
        </div>
        <div
          className="mobile-project-filter-selection"
         
        >
          <div className="mobile-project-filter-selection-option"
            onClick={(e) => setShowFilterDropDown(!showFilterDropDown)}
          >
            <span style={{ marginRight: 15 }}>
              {selectedFilter === "faramland" && "Farm Land"}
              {selectedFilter === "residential" && "Residential"}
            </span>
            <svg
              style={{ position: "relative", top: -3 }}
              width="10"
              height="5"
              viewBox="0 0 10 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0L5 5L10 0H0Z" fill="#209541" />
            </svg>
          </div>
        </div>
        {showFilterDropDown && (
          <div className="mobile-project-filter-selection-option-2"
            onClick={(e) => {
              if (selectedFilter === "faramland") {
                setSelectedFilter("residential");
                filter("RESIDENTIAL-PLOTS");
              }
              if (selectedFilter === "residential") {
                setSelectedFilter("faramland");
                filter("FARMLAND");
              }
              setShowFilterDropDown(false);
            }}
          >
            {selectedFilter === "faramland" && "Residential"}
            {selectedFilter === "residential" && "Farm Land"}
          </div>
        )}
        <div className="project-cards">
          <div className="project-slider-action-holder">
            <div
              className="project-carousal-left-chevron-btn"
              onClick={() => {
                console.log(sliderRef);
                sliderRef.current.slickPrev();
              }}
            >
              <svg
                width="10"
                height="19"
                viewBox="0 0 10 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.9375 1.47925L0.916667 9.50008L8.9375 17.5209"
                  stroke="#5D5F5F"
                  strokeWidth="1.8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>

            <div className="project-cards-container">
            <div style={{}}>
              <Slider {...settings} ref={sliderRef}>
                {projects.map((d, i) => {
                  return (
                    <div key={"k" + i}>
                      <a
                        style={{ textDecoration: "none" }}
                        href={"/project/" + d.id}
                      >
                        <div className="project-cards-section">
                          <div className="project-cards-section-block">
                            <div className="project-cards-section-image">
                              <img
                                src={d.image && d.image[0]}
                                alt={d.title}
                                className="project-card-section-image"/>
                            </div>
                            <div className="project-card-section-title"
                            >
                              {d.title}
                            </div>
                            <div className="project-card-sub-title">
                              {d.description}
                            </div>
                            <div  className="project-card-primary-btn">
                              <span
                                className="project-card-primary-btn-txt">
                                PROJECT DETAILS
                              </span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>

          <div className="project-slider-action-holder">
            <div
              className="project-carousal-right-chevron-btn"
              onClick={() => {
                console.log(sliderRef);
                sliderRef.current.slickNext();
              }}
            >
              <svg
                width="10"
                height="19"
                viewBox="0 0 10 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.0625 17.5208L9.08333 9.49992L1.0625 1.47908"
                  stroke="#5D5F5F"
                  strokeWidth="1.8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="project-slider-small-action-holder">
          <div
            className="project-carousal-left-chevron-btn"
            style={{ marginTop: 30, left: 0, marginBottom: 30}}
            onClick={() => {
              console.log(sliderRef);
              sliderRef.current.slickPrev();
            }}
          >
            <svg
              width="10"
              height="19"
              viewBox="0 0 10 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.9375 1.47925L0.916667 9.50008L8.9375 17.5209"
                stroke="#5D5F5F"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div style={{ width: 18 }}></div>
          <div
            className="project-carousal-right-chevron-btn"
            style={{ marginTop: 30, left: 0 }}
            onClick={() => {
              console.log(sliderRef);
              sliderRef.current.slickNext();
            }}
          >
            <svg
              width="10"
              height="19"
              viewBox="0 0 10 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.0625 17.5208L9.08333 9.49992L1.0625 1.47908"
                stroke="#5D5F5F"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </PageSection>
    </div>
  );
};

function loadprojects(fi, cb) {
  var myHeaders = new Headers();
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AVMjWRXtLoViz1nDUyIXJhuFsyJAlPltr.pXO57A0bsp%2FQMBh6x22Ze5rrVm2tGzixJwqUy1q2nAc"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  let path = "/Properties";
  if (fi) {
    path += "?type=" + fi;
  }
  fetch(APIBASE + path, requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
}
export default Projects;
