import "./index.css";
import PageSectionHeader from "../PageSectionHeader";
const PageSection = ({
  children,
  title,
  subTitle,
  noHeader = false,
  bgImage,
  style,
}) => {
  return (
    <div
      className="page-section"
      style={{
        backgroundImage: bgImage ? `url("${bgImage}")` : "none",
        ...style,
      }}
    >
      <div style={{ maxWidth: 1440, margin: "auto" }}>
        {!noHeader && <PageSectionHeader title={title} subTitle={subTitle} />}
        {children}
      </div>
    </div>
  );
};

export default PageSection;
