import React from "react";

import {
  createBrowserRouter,
  RouterProvider,
  // Route,
  useParams,
} from "react-router-dom";

import "./App.css";
// import Header from "./components/Header";
import Navbar from "./components/NavBar";

import Amenities from "./components/Amenities";
import Projects from "./components/Projects";
// import p1 from "../src/assets/projects/p1.png";
import InsightsUtilities from "./components/InsightsUtilities";
import WhyUs from "./components/whyUS";
import WhyInvestInMysuru from "./components/WhyInvestInMysuru";
import TreandingProjects from "./components/TrendingProjects";
import UpcomingProjects from "./components/UpcomingProjects";
import ContactInfo from "./components/ContactInfo";
import FooterSection from "./components/FooterSection";
import OurCustomersSpeak from "./components/OurCustomersSpeak";
import Award from "./components/Award";
import PropertyDetails from "./components/PropertyDetails";
 import ConnectwithUs from "./components/ConnectwithUs";
import HomeSlider from "./components/HomeSlider";
import About from "./components/About";
import BlogSection from "./components/BlogSection";

function Home() {
  let callingFunction;
  function updateProjectFilter(filter){
    console.log(callingFunction,filter)
    callingFunction(filter) 
  }

  return (
    <div className="App" style={{ overflow: "scroll" }}>
      <Navbar updateProjectFilter={updateProjectFilter}/>
      {/* <Header updateProjectFilter={updateProjectFilter}/> */}
      <HomeSlider/>
      <ConnectwithUs/>
      <Amenities />
      <Projects getCallingFunction={(cf)=>{
        console.log(cf)
        callingFunction=cf
        }}/>
      <InsightsUtilities /> 
      <WhyUs />
      <WhyInvestInMysuru />
      <TreandingProjects />
      <UpcomingProjects />
      <OurCustomersSpeak />
      <Award />
      
      <ContactInfo />
      <FooterSection />

      {/* <img src={p1} /> */}
    </div>
  );
}
const ProjectRoute = () => {
  let { id } = useParams();
  return (
    <div className="App" style={{ overflow: "scroll" }}>
      <Navbar />
      <PropertyDetails id={id} />
      <FooterSection />
    </div>
  );
};
const AboutUs = () => {
  return (
    <div className="App" style={{ overflow: "scroll" }}>
      <Navbar />
       <About/>
      <FooterSection />
    </div>
  );
};
const Blog = () => {
  return (
    <div className="App" style={{ overflow: "scroll" }}>
      <Navbar />
       <BlogSection/>
      <FooterSection />
    </div>
  );
};
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/project/:id",
    element: <ProjectRoute />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/blogs",
    element: <Blog />,
  },
]);
const App = () => (
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
export default App;
