import "./index.css";

const BreadCrumbs = ({ path = [] }) => {
  return (
    <div
      style={{
        display: "flex",
        maxWidth: 1440,
        margin: "auto",
        paddingTop: 25,
        paddingBottom: 25,
      }}
    >
      {path.map((d, i) => {
        if (path.length !== i + 1)
          return (
            <>
              <div key={"bc" + i} className={"bc-link"}>
                {d}
              </div>
              <div className={"bc-link"}>/</div>
            </>
          );
      })}
      <div className={"bc-link-current"}>{path[path.length - 1]}</div>
    </div>
  );
};
export default BreadCrumbs;
