import "./index.css";

const index = ({ title, subTitle }) => {
  return (
    <div className="page-section-header-container">
      <div className="page-section-header-title">{title}</div>
      <div className="page-section-header-subtitle">{subTitle}</div>
    </div>
  );
};

export default index;
