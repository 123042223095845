import "./index.css";
import { useEffect, useRef, useState } from "react";
import PageSection from "../global/PageSection";
import Slider from "react-slick";
import { APIBASE } from "../../apiCondig";

import upcoming from "./images/upcoming1.png";
import { getQueriesForElement } from "@testing-library/react";
const UpcomingProjects = ({ }) => {
  const sliderRef = useRef();
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    loadprojects((resut) => {
      try {
        setProjects(JSON.parse(resut));
      } catch (e) {
        console.log(e);
      }
    });
  }, []);
  let settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,


  };
  useEffect(() => { }, []);
  const [selectedFilter, setSelectedFilter] = useState("residential");
  return (
    <PageSection
      title={"Upcoming Projects"}
      subTitle={"Handpicked projects for you"}
      // bgImage={bgImage}
      style={{
        //minHeight: 838,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "center",
        background: "#FBFFF5",

      }}
    >
      <div className="up-coming-project-section">
        <div className="up-coming-project-container">
          <div style={{}}>
            <Slider {...settings} ref={sliderRef}>
              {projects.map((d, i) => {
                return (
                  <div key={"k" + i}>
                    <div >
                      <img src={upcoming} alt={"upcoming"} className="up-coming-project-image" />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

function loadprojects(cb) {
  var myHeaders = new Headers();
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AVMjWRXtLoViz1nDUyIXJhuFsyJAlPltr.pXO57A0bsp%2FQMBh6x22Ze5rrVm2tGzixJwqUy1q2nAc"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(APIBASE + "/Properties", requestOptions)
    .then((response) => response.text())
    .then((result) => cb(result))
    .catch((error) => console.log("error", error));
}
export default UpcomingProjects;
